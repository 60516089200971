import React from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import QuoteForm from '../../components/QuoteForm';
import LightPage from '../../components/LightPage';

import { SectionHeading, Note } from '../../components/textComponents';
import theme from '../../data/theme';

const keywords = [
  'VLP',
  'Landscaping',
  'Landscaper',
  'Matt Vojticek',
  'Customer Service',
  'Northeast Ohio',
];

const QuoteFormWrapper = styled.div`
  max-width: 40rem;
  min-width: 30rem;
  margin: 5rem auto;
  /* padding: 2.5rem; */
  /* background-color: ${ theme.colors.lightcloud }; */
`;

const page = {
  title: 'Service Quote',
  keywords: keywords,
  description: 'If you have a landscaping or lawn maintenance project, VLP Landscaping is here to help.  We will gladly provide you with a free service quote.',
};

const heading = {
  title: page.title,
};

export default () => (
  <LightPage page={page} heading={heading}>
    <QuoteFormWrapper>
      <SectionHeading>Get A Quote</SectionHeading>
      <Note>Just fill this out, and we will contact you to discuss your needs.</Note>
      <QuoteForm />
    </QuoteFormWrapper>
  </LightPage>
);
